@import "../../scss/defalut";
.HeaderContainer{

  width: 100%;
  top: 0;
  position: sticky;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  pointer-events: bounding-box;
  .Header{
    
    padding: 1.5rem 2rem;
    display: flex;

    align-items: center;
    justify-content: space-between;

    transition: all .5s;
    .Logo{
      cursor: pointer;
    }
    .MenuList{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 3rem;

      .Menu{
        @extend %ContentDescriptionSSmall;
        @extend %Secondary;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
    .Hamburger{
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    .DropdownMenu {
      padding: 1rem 0 !important;
      border-radius: 0.5rem !important;
      border: 1px solid var(--ColorStyles-Gray-200, #EEF5F9) !important;
      background: var(--ColorStyles-DefaultColor-White, #FFF) !important;
      box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.05);
      margin-top: 1rem;
      li {
        color: #6C757D;
        cursor: pointer;
        padding: 0.5rem 1rem;
        @extend %ContentDescriptionSSmall;
      }

      li:hover {
        color: #343A40 !important;
        background-color: #EEF5F9 !important;
      }
    }

  }
}
