@import '../scss/defalut';

.HeaderContent{
  background: #f2f2f2;
  display: flex;
  width: 100%;
  @include media-breakpoint-down(sm){
    padding-top: 3rem;
  }
  .Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 6.6875rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem;
      text-align: center;
    }
    .LightTitle{
      @extend %HeaderH2Light;
    }
    .LightTitleBold{
      @extend %HeaderH3Bold;
      padding-bottom: 1rem;
    }
    .Description{
      @extend %ContentDescriptionSmall;
      @extend %Secondary;
      word-break: keep-all;
      padding-bottom: 2rem;
      white-space: pre-wrap;
    }
  }
  .Image {
    width: 100%;
    animation-duration: 1s;
    @include media-breakpoint-down(sm){
      animation-name: slideInDown;
    }
  }

  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(2rem)
    }

    to {
      opacity: 1;
      transform: translateX(0)
    }
  }

  @keyframes slideInDown {
    from {
      opacity: 0;
      transform: translateY(-2rem)
    }

    to {
      opacity: 1;
      transform: translateY(0)
    }
  }
}

.Section {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  align-items: center;
  width: 100%;

  .Container {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    padding: 0;

    .Tabs {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8rem;
      padding-bottom: 8rem;
      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
      }
      .Title {
        @extend %HeaderH1Bold;
      }

      .Description {
        @extend %ContentDescriptionMedium;
        @extend %Secondary;
        text-align: center;
        white-space: pre-wrap;
      }
    }

    .Data {
      display: flex;
      flex-direction: column;
      margin: 8rem 0 8rem 0;

      .TitleContainer {
        margin-bottom: 2rem;
        white-space: pre-wrap;
        @include media-breakpoint-down(sm) {
          padding: 0 1rem;
        }
        .MainTitle {
          @extend %ContentTitleLargeBold
        }
        .SubTitle {
          @extend  %ContentTitleLarge;
          word-break: keep-all;
        }
      }
      .DescContainer {
        display: flex;
        flex-direction: column;
        .Item {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 2rem;
          padding: 2rem;
          border-bottom: 1px solid var(--ColorStyles-Gray-background, #F2F2F2);
          @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
          }
          .Desc {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 1rem;
            .Title {
              @extend %ContentTitleMediumBold;
              margin-bottom: 2rem;
            }
            .Subject {
              @extend %ContentDescriptionSmallBold;
              padding:0;
              margin: 0;
            }
            .Content {
              @extend %ContentDescriptionSmall;
              padding:0;
              margin: 0;
            }
          }
        }
      }
      .ImageContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 2rem;
        align-items: stretch;
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr 1fr ;
        }
        .Item {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 1rem 0.63rem;
          .Label {
            @extend %ContentDescriptionSmall;
            margin-top: 1rem;
            text-align: center;
          }
        }
      }
    }
  }
}


