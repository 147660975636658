@import '../../scss/breakpoints';

.FadeCarousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  .Image {
    width: 100%;
    object-fit: cover;
    object-position: left;
    position: absolute;
    opacity: 0;
    right: -4rem;
    @include media-breakpoint-down(sm) {
      top: -4rem;
      right: 0;
    }

    &.FadeIn {
      opacity: 1;
      right: 0;
      position: relative;
      @include media-breakpoint-down(sm) {
        top: 0;
        right: 0;
      }
    }

    &.FadeOut {
      opacity: 0;
      right: 4rem;
      @include media-breakpoint-down(sm) {
        top: 4rem;
        right: 0;
      }
    }
  }
}