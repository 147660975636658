@import "../../scss/defalut";
.CarouselItem{
  margin: 0 2rem;
  border-radius: 2rem;

  overflow: hidden;
  img{
    background: #f2f2f2;
  }
  @include media-breakpoint-down(sm) {
    margin: 0;
    border-radius: 0;
  }
  .Content{
    position: absolute;
    padding: 2rem;
    @include media-breakpoint-down(sm) {
      position: relative;
      padding: .5rem;
    }
    .Title{
      @extend %ContentDescriptionMedium;
      @extend %Secondary;
    }
    .Description{
      @extend %ContentTitleMediumBold;
    }
  }
  img{
    width: 100%;
  }
}