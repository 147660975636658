@import "breakpoints";
%DefaultColor{
  color: #2d2d2d;
}

%HeaderH1Light{
  @extend %DefaultColor;
  font-size: 5rem;
  font-weight: 300;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}

%HeaderH1Bold{
  @extend %HeaderH1Light;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}

%HeaderH2Light{
  @extend %DefaultColor;
  font-size: 3.5rem;
  font-weight: 300;
  @include media-breakpoint-down(sm) {
    font-size: 2.25rem;
  }
}

%HeaderH3Bold{
  @extend %DefaultColor;
  font-size: 3rem;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

%ContentTitleLarge{
  @extend %DefaultColor;
  font-size: 2.625rem;
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

%ContentTitleLargeBold{
  @extend %ContentTitleLarge;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

%ContentTitleMedium{
  @extend %DefaultColor;
  font-size: 2rem;
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 1.375rem;
  }
}

%ContentTitleMediumBold{
  @extend %ContentTitleMedium;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 1.375rem;
  }
}

%ContentDescriptionMedium{
  @extend %DefaultColor;
  font-size: 1.75rem;
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}
%ContentDescriptionMediumBold{
  @extend %ContentDescriptionMedium;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}

%ContentDescriptionSmall{
  @extend %DefaultColor;
  font-size: 1.5rem;
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
  }
}

%ContentDescriptionSmallBold{
  @extend %ContentDescriptionSmall;
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
  }
}


%ContentDescriptionSSmall{
  @extend %DefaultColor;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: -0.045rem;
  @include media-breakpoint-down(sm) {
    font-size: .9rem;
  }
}

%ContentDescriptionSSmallBold{
  @extend %ContentDescriptionSSmall;
  font-weight: 700;
}

%Secondary{
  color: var(--bs-secondary) !important;
}