@import '../../scss/_breakpoints.scss';



.HorizonLine {
  width: 100%;
  scale: 1 1;

  @include media-breakpoint-down(sm) {
    scale: 1 0.5;
  }
}