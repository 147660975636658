@import '../scss/defalut';
.HeaderContent{
  background: #f2f2f2;
  display: flex;
  width: 100%;
  .Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 6.6875rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem;
      text-align: center;
    }
    .LightTitle{
      @extend %HeaderH2Light;
    }
    .LightTitleBold{
      @extend %HeaderH3Bold;
      padding-bottom: 1rem;
    }
    .Description{
      @extend %ContentDescriptionSmall;
      @extend %Secondary;

      padding-bottom: 2rem;
      white-space: pre-wrap;
    }
  }
  .Image {
    width: 100%;
    animation-duration: 1s;
    animation-name: slideInLeft;
    @include media-breakpoint-down(sm){
      animation-name: slideInDown;
    }
  }

  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(2rem)
    }

    to {
      opacity: 1;
      transform: translateX(0)
    }
  }

  @keyframes slideInDown {
    from {
      opacity: 0;
      transform: translateY(-2rem)
    }

    to {
      opacity: 1;
      transform: translateY(0)
    }
  }
}


.Section {
  display: flex;
  flex-direction: column;
  padding: 12.5rem 0;
  align-items: center;
  width: 100%;
  &.Gray {
    background: var(--ColorStyles-Gray-background, #F2F2F2);
  }
  .Container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0;
    .Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8rem;
      padding-bottom: 8rem;
      .Title {
        @extend %HeaderH1Bold;
      }

      .Description {
        @extend %ContentDescriptionMedium;
        @extend %Secondary;
        text-align: center;
        white-space: pre-wrap;
      }
    }

    %Data {
      display: flex;
      gap: 2rem;
    }

    %Text {
      .Text {
        display: flex;
        flex: 3;
        @include media-breakpoint-down(sm) {
          flex: 1;
          padding: 1rem;
        }
        flex-direction: column;
        gap: 1rem;
        .Subject {
          @extend %ContentTitleMediumBold;
          white-space: pre-wrap;
        }
        .Content {
          @extend %ContentDescriptionSmall;
          margin: 0;
          padding: 0;
        }
      }
    }

    %Image {
      .Image {
        img{
          width: 100%;
        }
        flex: 2;
        @include media-breakpoint-down(sm) {
          flex: 1;
        }
        align-self: center;
      }
    }

    .Odd {
      @extend %Data;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      @extend %Text;
      @extend %Image;
    }
    .Even {
      @extend %Data;
      flex-direction: row-reverse;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      @extend %Text;
      @extend %Image;
    }

    .SectionText {
      flex: 1;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;
      padding: 1rem;
      @include media-breakpoint-down(sm) {
        white-space: normal;
      }
      &.StartAlign {
        align-items: start;
      }
      &.CenterAlign {
        align-items: center;
        text-align: center;
      }
      .Title {
        @extend %HeaderH1Bold;
      }
      .Subject {
        @extend %ContentTitleMediumBold;
        white-space: pre-wrap;
      }
      .Content {
        @extend %ContentTitleMedium;
        margin: 0;
        padding: 0;
      }
    }

    .BrandLogo {
      display: grid;
      grid-template-columns: repeat(6,1fr);
      @include media-breakpoint-down(sm){
        grid-template-columns: 1fr 1fr 1fr;
      }
      gap: 1rem;
      .Logo {
        display: flex;
        padding: 0.62rem;
        flex-direction: column;
        align-items: center;
        gap: 0.62rem;
        .Image {
          width: 11.42188rem;
          height: 3.3925rem;
          @include media-breakpoint-down(sm) {
            width: 5.5rem;
            height: 1.6rem;
          }
        }
        .Label {
          text-align: center;
        }
      }
    }

    .BuiltinModule {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 2rem;
        flex-wrap: wrap;
        @include media-breakpoint-down(sm) {
          padding: 0 1rem;
        }
        .Item {
            height: 25.9375rem;
          @include media-breakpoint-down(sm) {
            height: 15rem;
          }
        }
    }

    .TabItem {
      display: flex;
      flex-direction: column;
      border-top: 1px solid var(--ColorStyles-DefaultColor-Secondary, #6C757D);
      border-bottom: 1px solid var(--ColorStyles-DefaultColor-Secondary, #6C757D);
      .TabTitle {
        display: flex;
        padding: 2rem 0;
        justify-content: center;
        @extend %ContentDescriptionSmallBold;
      }
      .TabIcons {
        padding: 2rem;
        @include media-breakpoint-down(sm) {
          padding: 2rem 1rem;
        }
        border-top: 1px solid var(--ColorStyles-Gray-400, #CED4DA);
      }
    }
  }




}