@import "../../scss/defalut";
.TabHeaderContainer {
  width: 100%;
  top: 5rem;
  position: sticky;
  z-index: 9;
  display: none;
  transition: all .5s;
  @include media-breakpoint-down(sm){
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }
}