@import '../scss/defalut';
.HeaderContent{
  background: #f2f2f2;
  display: flex;
  .Container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0;
    @include media-breakpoint-down(sm){
      flex-direction: column;
    }
    .ImageContent{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      animation-duration: 1s;
      animation-name: slideInLeft;
      @include media-breakpoint-down(sm){
        animation-name: slideInDown;
      }
    }

    @keyframes slideInLeft {
      from {
        opacity: 0;
        transform: translateX(2rem)
      }

      to {
        opacity: 1;
        transform: translateX(0)
      }
    }

    @keyframes slideInDown {
      from {
        opacity: 0;
        transform: translateY(-2rem)
      }

      to {
        opacity: 1;
        transform: translateY(0)
      }
    }

    .Content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 6.6875rem;
      @include media-breakpoint-down(sm) {
        padding: 2rem 1rem;
        text-align: center;
      }
      .LightTitle{
        @extend %HeaderH2Light;
      }
      .LightTitleBold{
        @extend %HeaderH3Bold;
        padding-bottom: 1rem;
      }
      .Description{
        @extend %ContentDescriptionSmall;
        @extend %Secondary;

        padding-bottom: 2rem;
        white-space: pre-wrap;
      }
    }
  }

}

.IntroContainer{

  .Content{
    padding: 6rem 4rem 5rem;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
    }
    .Title{
      @extend %ContentTitleLargeBold;
    }
    .SubTitle{
      @extend %ContentTitleLarge;
    }
    .Image{
      padding: 4rem 0;
      img{
        width: 100%;
        height: auto;
      }
    }

    .DescriptionTitle{
      @extend %ContentDescriptionMediumBold;
      @extend %Secondary;
    }
    .Description{
      @extend %ContentDescriptionMedium;
      @extend %Secondary;
      padding-top: 1rem;
      white-space: pre-wrap;
    }
  }
}


.ServiceContent{
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;

  .Content{

    text-align: center;
    padding: 6rem;
    white-space: pre-wrap;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
      white-space: normal;
    }
    .Title{
      @extend %HeaderH1Bold;
    }
    .SubTitle{
      @extend %HeaderH1Light;
    }

  }
  .ContactCards{
    display: flex;
    justify-content: center;
    padding: 5.5rem;
    width: 100%;
    gap: 2rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 1rem;
    }
    .ContactCard{
      padding: 4rem 8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      background: #fff;
      @include media-breakpoint-down(sm) {
        padding: 2rem 1rem;
      }
      .Icon{
        @extend %Secondary;
        font-size: 3rem;
      }
      .Title{
        @extend %ContentTitleMediumBold;
        padding: 1.5rem;
        white-space: pre-wrap;
        text-align: center;
      }
      .Description{
        @extend %ContentDescriptionSmall;
        @extend %Secondary;
        white-space: pre-wrap;
        text-align: center;
      }
      .Button{
        button{
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: .5rem;
          svg {
            align-self: center;
            margin-right: 0.5rem;
            fill: var(--bs-success);
          }
          &:hover svg {
            fill: #FFFFFF;
          }
        }
        padding: 2rem;
      }
    }
  }
}

.RadiusButton{
  white-space: nowrap;
  border-radius: 3rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.96px;
  padding: 1.5rem;
}

.MemberContent{
  display: flex;
  flex-direction: column;
  padding: 12.5rem 2rem;
  gap: 4rem;
  @include media-breakpoint-down(sm) {
    padding: 12.5rem 1rem;
  }
  .Container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0;

    .Content{
      text-align: start;
      padding-bottom: 2rem;
      .Title{
        @extend %ContentTitleLargeBold;
      }
      .Description{
        @extend %ContentDescriptionMedium;
      }
    }

    .Team {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      .Title {
        @extend %ContentDescriptionMediumBold;
        text-align: center;
      }
      .MemberGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 4rem 6.75rem;
        grid-gap: 2rem;
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr 1fr;
          padding: 0;
          grid-gap: 1rem;
        }
        .Member{
          padding: 2rem;
          border-radius: 1rem;
          border: 1px solid var(--ColorStyles-Gray-background, #F2F2F2);
          background: var(--ColorStyles-DefaultColor-White, #FFF);
          box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-down(sm) {
            padding: 1rem;
          }
          .Avatar{
            width: 9.375rem;
            height: 9.375rem;
            border-radius: 50%;
            overflow: hidden;
            background: #d9d9d9d9;
            @include media-breakpoint-down(sm) {
              width: 4.375rem;
              height: 4.375rem;
            }
            img{
              width: 100%;
              height: 100%;
            }
          }
          .Type{
            @extend %ContentDescriptionSmall;
          }
          .Name{
            @extend %ContentTitleLargeBold;
            padding-top: 1rem;

          }
          .Phone{
            @extend %ContentDescriptionMedium;
            white-space: pre-wrap;
            word-break: break-all;
            padding-top: .5rem;
          }
        }
      }
    }
  }

}

.MapContent{
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;

  .Content{

    text-align: center;
    padding: 6rem;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
    }
    .Title{
      @extend %HeaderH1Bold;
      white-space: pre-wrap;
    }
    .SubTitle{
      @extend %HeaderH1Light;
      white-space: pre-wrap;
    }
  }
  .Map{
    position: relative;

    width: 100%;
    height: 28rem;
    padding: 0 4rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }
  .Address{
    text-align: center;
    padding-top: 4rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem;
      word-break: break-word;
      white-space: pre-wrap;
      text-align: start;
    }
  }

}