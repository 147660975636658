@import '../../scss/defalut';
$active-color: #6C757D;
$inactive-color: #CED4DA;
.Tabs {
  display: flex;
  gap: 2rem;
  justify-content: center;
  border: 0;
  @include media-breakpoint-down(sm) {
    justify-content: space-evenly;
    gap: 1rem;
    margin: 0;
  }
  .TabItemContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 2rem;
    cursor: pointer;
    @extend %ContentDescriptionSmall;
    @include media-breakpoint-down(sm) {
      gap: 0.1rem;
      margin: 0;
    }

    .TabItem {
      align-self: center;
      padding: 0;
      width: 6rem;
      height: 6rem;
      @include media-breakpoint-down(sm) {
        width: 3rem;
        height: 3rem;
      }

    }
    .TabLabel {
      color: $inactive-color;
      text-align: center;
      white-space: pre-wrap;
    }

    svg {
      fill :$inactive-color;
    }

    &.Active {
      svg {
        fill: $active-color;
      }
      .TabLabel {
        color: $active-color;
      }
    }

  }
}