@import "../../scss/defalut";
.Footer{
  display: flex;
  flex-direction: column;
  background: #2F4479;
  padding: 8rem 5rem;
  color: #fff;
  @include media-breakpoint-down(sm) {
    padding: 5rem 2rem;
    justify-content: center;
  }
  .Links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    @include media-breakpoint-down(sm) {
      padding: 1.5rem 0 0 0;
      justify-content: center;

    }
    .Link{
      @extend %ContentDescriptionSSmallBold;
      color: #fff;
    }
  }
  .Line{
    padding: 0 2rem 0 0;
    color: #fff;
  }

  .Title{
    @extend %ContentDescriptionSmallBold;
    color: #fff !important;
    letter-spacing: -0.88px;
    padding-bottom: .5rem;
    padding-top: 2rem;
  }
  .Tel{
    @extend %ContentTitleMediumBold;
    color: #fff !important;
  }
  .Description{
    @extend %ContentDescriptionSSmall;
    color: #fff !important;
    white-space: pre-wrap;
    word-break: break-all;
  }
  .Copy{
    @extend %ContentDescriptionSSmall;
    padding-top: 2rem;
    color: #fff !important;
  }
}
