@import "../../scss/defalut";

.ContactPanel{
  z-index: 99999;
  position: absolute;
  left: 0;
  transition: all .5s cubic-bezier(.4,-0.27,.45,1.25);
  pointer-events: none;
  @include media-breakpoint-up(sm) {
    transform: translateX(calc(-100% + 57px));
    top: 0;
    height: 100%;
  }
  @include media-breakpoint-down(sm) {
    bottom: 0;
    transform: translateY(calc(100% - 57px));
    width: 100%;
  }
  &.Open{
    @include media-breakpoint-up(sm) {
      transform: translateX(-57px);
    }
    @include media-breakpoint-down(sm) {
      transform: translateY(57px);
    }

  }
  .TopHandle{

    img{
      cursor: grab;
      pointer-events: bounding-box;
    }
    pointer-events: none;
    display: none;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }
  .ContentContainer{
    display: flex;
    height: 100%;
    width: 100%;

    .Content{

      height: 100%;
      flex: 1;
      @include media-breakpoint-up(sm) {
        padding-left: 40px;
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
      }
      .Form{
        pointer-events: all;
        height: 100%;
        width: 100%;
        border: 3rem solid #343A40;
        transform: rotate(1deg) translateX(-10px);
        background: #fff;
        box-shadow: 64px 64px 64px 0px rgba(0, 0, 0, 0.25) inset, -32px -32px 32px 0px rgba(0, 0, 0, 0.25) inset;
        padding: 1rem;
        @include media-breakpoint-down(sm) {
          border: 2rem solid #343A40;
          transform: rotate(1deg) translateY(3px);
        }
        .Header{
          transform: rotate(1deg);
          display: flex;
          justify-content: space-evenly;
          padding: 1rem;
          @include media-breakpoint-down(sm) {
            padding: 0;
          }
          .Dot{
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
            background: #343A40;
            margin: .7rem;

          }
        }
        .Title{
          @extend %ContentTitleLargeBold;
          text-align: center;
          margin-bottom: 2rem;
          margin-left: 1rem;
          margin-right: 1rem;
          @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
        .Row{
          @extend %ContentDescriptionSmallBold;
          white-space: nowrap;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 2rem;
          margin-left: 1rem;
          margin-right: 1rem;
          justify-content: space-between;

          @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 1rem;
          }
          .Input{
            background: transparent;
            border-radius: 0.25rem;
            border: 1px solid var(--color-secondary, #6C757D);
          }
        }
      }
      .ContentCover{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;


        @include media-breakpoint-up(sm) {
          padding-top: 1px;
          border-top: 1rem solid #143C64;
          border-bottom: 1rem solid #143C64;
          transform: translateX(-3px);

        }
        @include media-breakpoint-down(sm) {
          padding-left: 0px;
          padding-right: 0px;
          border-left: 1rem solid #143C64;
          border-right: 1rem solid #143C64;
          transform: translateX(0px);
        }
        .HandleBar{

          height: 100%;
          width: 100%;
          @include media-breakpoint-up(sm) {

            border-right: 1rem solid #143C64;
          }
          @include media-breakpoint-down(sm) {
            border-top: 1rem solid #143C64;
          }
        }
      }

    }
    .RightHandle{
      transform: translateX(-3px);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      img{
        cursor: grab;
        pointer-events: bounding-box;
      }
      @include media-breakpoint-down(sm) {
        transform: translateX(0px);
        display: none;
      }
    }
  }
}
