@import "../../scss/defalut";


.SideMenu{
  position: absolute;
  flex: 1 1 0;
  z-index: 9;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: bounding-box;
  padding: 5.125rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  transition: all .5s;
  background-color: #FFFFFF;
  .Menu{
    padding: 0.5rem 0 0.5rem 1rem ;
    width: 100%;
    cursor: pointer;
  }

  .SubMenu {
    width: 100%;
    background-color: #F2F2F2;
    transition: all 0.2s;
    overflow: hidden;
    padding: .5rem 1rem;
    div {
      padding: .3rem .5rem;
    }
    &.On {
      max-height: 200px;
    }
    &.Off {
      max-height: 0;
      padding: 0 1rem;
    }
  }
}
