@import '../scss/defalut';

.HeaderContent{
  background: #f2f2f2;
  display: flex;
  .Container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .ImageContent{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .Content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 6.6875rem;
      @include media-breakpoint-down(sm) {
        padding: 2rem;
        text-align: center;
      }
      .LightTitle{
        @extend %HeaderH2Light;
      }
      .LightTitleBold{
        @extend %HeaderH3Bold;
        padding-bottom: 1rem;
      }
      .Description{
        @extend %ContentDescriptionSmall;
        @extend %Secondary;

        padding-bottom: 2rem;
        white-space: pre-wrap;
      }
      .Button {
        display: flex;
        justify-content: start;
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }
    }
  }

}

.CarouselContainer{
  .MobileCarousel{
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .Carousel{
    display: block;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .DotList{
    display: flex;
    padding: 1rem;
    gap: .5rem;
    border-radius: 3rem;
    border: 1px solid var(--bs-secondary);
    width: fit-content;
    margin: 4rem auto;

    .Dot{
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 1.25rem;
      transition: all .5s;
      background: var(--bs-secondary);
      &.Active{
        width: 7.5rem;
      }
    }
  }
  .Content{
    padding: 12.5rem 4rem 5rem;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
    }
    .Title{
      @extend %ContentTitleLargeBold;
    }
    .Description{
      @extend %ContentDescriptionMedium;
      @extend %Secondary;
      white-space: pre-wrap;
    }
  }
}


.ServiceContent{
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .Content{

    text-align: center;
    padding: 6rem;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
    }
    .Title{
      @extend %HeaderH1Bold;
      white-space: pre-wrap;
      padding: 0;
    }
    .SubTitle{
      @extend %ContentDescriptionMediumBold;
      white-space: pre-wrap;
      padding: 1rem;
      @include media-breakpoint-down(sm) {
        padding: 1rem 0;
        text-align: start;
      }
    }

    .Description{
      @extend %ContentDescriptionMedium;
      @extend %Secondary;
      white-space: pre-wrap;
      padding-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        text-align: start;
      }
    }
    .ContentText {

    }

  }
}


.ContactContent{
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .Content{

    text-align: center;
    padding: 6rem;
    @include media-breakpoint-down(sm) {
      padding: 5rem 1rem;
    }
    .Title{
      @extend %ContentTitleLargeBold;
      white-space: pre-wrap;
      padding: 0;
    }
    .SubTitle{
      @extend %ContentDescriptionMediumBold;
      white-space: pre-wrap;
      padding: 1rem;
      @include media-breakpoint-down(sm) {
        padding: 1rem 0;
        text-align: start;
      }
    }

    .Description{
      @extend %ContentDescriptionMedium;
      @extend %Secondary;
      white-space: pre-wrap;
      padding-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }
}

.RadiusButton{
  white-space: nowrap;
  border-radius: 3rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.96px;
  padding: 1.5rem;
  display: flex;
  svg {
    align-self: center;
    margin-right: 0.5rem;
    fill: #6C757D;
  }
  &:hover svg {
    fill: #FFFFFF;
  }
}

.MaterialContent{

  .Content{
    padding-bottom: 2rem;
    .Title{
      @extend %ContentTitleLargeBold;
    }
    .Description{
      @extend %ContentDescriptionMedium;
    }
  }
  padding: 4rem;
  @include media-breakpoint-down(sm) {
    padding: .5rem;
    padding-top: 2rem;
  }
  .LibraryGrid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      grid-gap: .5rem;
    }
    .Library{
      border: 1px solid #6C757D;
      padding: 1rem;
      border-radius: 1rem;
      .Icon{
        width: 2.5rem;
        height: 2.5rem;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .Title{
        @extend %ContentDescriptionSmallBold;
        padding-top: 1rem;

      }
      .Description{
        @extend %ContentDescriptionSSmall;
        white-space: pre-wrap;
        word-break: break-all;
        padding-top: .5rem;
      }
      .Buttons{
        padding-top: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .Button{
          font-size: 1.2rem;
          border-radius: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}



